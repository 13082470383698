import React from 'react'
import Layout from '../layout/Layout';
import Seo from '../layout/Seo';

const NotFoundPage = ({ pageContext }) => {

    const { locale } = pageContext;
    function Child({locale}){
        return(
            <Seo 
            title="Error 404" 
            description="Error 404"
            pathname="https:localhost"
        />
        )
    }
    return (
        <Layout locale={locale} background="bg-image-remodeling">
            {props => <Child {...props} />}
        </Layout>
    )
}

export default NotFoundPage